import React, { useState, useEffect } from "react"
import { SectionTitle } from "../../components/styled"
import JobContent from "../../components/JobContent"
import SearchJob from "../../components/SearchJob"
import { Seo } from "../../components/seo"
import useJobs from "../../hooks/useJobs"
import { graphql } from "gatsby"
const JobsCategoriesTemplate = props => {
  const dataJobs = useJobs("English")
  const dataJobCategorie = props.data.allStrapiJobs.nodes
  const [dataGroupCat, setDataGroupCat] = useState([])
  useEffect(() => {
    if (dataJobs !== undefined) {
      for (let i = 0; i < dataJobs.length; i++) {
        if (dataJobCategorie[0].JobCategorie === dataJobs[i].JobCategorie) {
          setDataGroupCat(prevState => [...prevState, dataJobs[i]])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Seo
        title={`job-categorie: ${dataJobCategorie[0].JobCategorie} | Mexico job, career change and employment information [QUICK GLOBAL MEXICO]`}
      />
      <section className="container jobs-listing">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9" style={{ padding: 0 }}>
            <div className="jobs-listing__search">
              <SectionTitle>
                Mexico Jobs, Employment and Career Change Information
              </SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob lang={"en"} />
              </div>
            </div>
            <div
              className="jobs-listing__recommended-jobs"
              style={{ padding: "0" }}
            ></div>
            <div className="jobs-listing__job-info">
              <SectionTitle>
                {dataJobCategorie[0].JobCategorie} :{" "}
                {dataGroupCat.length === 1
                  ? dataGroupCat.length + " result"
                  : dataGroupCat.length + " result"}
              </SectionTitle>
              <hr className="u-line-bottom" />
              {dataGroupCat.map((jobs, i) => (
                <>
                  {dataJobCategorie[0].JobCategorie === jobs.JobCategorie && (
                    <React.Fragment key={"jobContent_" + i}>
                      <JobContent jobs={jobs} lang={"en"} />
                    </React.Fragment>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobsCategoriesTemplate
export const query = graphql`
  query jobCategoriesEn($slug: String!) {
    allStrapiJobs(filter: { JobCategorie: { eq: $slug } }) {
      nodes {
        JobCategorie
      }
    }
  }
`
